import React, { FC, useState, useEffect, useMemo, useCallback, useRef } from 'react'
import InsetContainer from '@/components/layouts/InsetContainer'
import Container from '@/components/layouts/Container'
import { Route, Routes, Navigate, useNavigate } from 'react-router'
import { parseXLSFile, parseXLSJSWorkbook, extractFormat, guessFormat } from '@/utils/casecard_xls'
import { usePromise } from '@/hooks/usePromise'
import { CasecardImportPreviewStage } from './stages/CasecardImportPreviewStage'
import {
  RawWorkbook,
  SheetFormat,
  CurrentWorkbook,
  columnTypeNames,
  columnsRequiredForInvoiceImport,
  ColumnType,
  SheetConfig
} from '@/models/casecard_import/xls'
import { ImportBreadcrumbs } from './components/ImportBreadcrumbs'
import { useIsMountedRef } from '@/hooks/useIsMountedRef'
import { formatArrayReadable } from '@/utils/array'
import * as R from 'ramda'
import { CasecardImportConfirmStage } from './stages/CasecardImportConfirmStage'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import Alert from 'antd/lib/alert'
import Spinner from '@/components/Spinner'
import { Link } from 'react-router-dom'
import { defaultImportOptions, sheetFormat2ImportOptions, ImportOptions, ImportContext } from '@/models/casecard_import/plan'
import excelImg from '@/images/excel-icon.png'
import { PayupDropzone } from '@/components/PayupDropzone'
import Fetch from '@/components/Fetch'
import { GetImportContext, GetImportContextVariables } from '@/queries/_gen_/GetImportContext'
import { GetImportContextQuery } from '@/queries/import.queries'
import { useApolloClient } from 'react-apollo'

const ACCEPTED_MIME_TYPES: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']

// @TODO export some of the logic here into separate hooks

const ImportViewInner: FC<{ importContext: ImportContext }> = ({ importContext }) => {
  const [file, setFile] = useState<File | null>(null)
  const [parsePromise, setParsePromise] = useState<Promise<[RawWorkbook, SheetConfig]> | null>(null)
  const parsePromiseState = usePromise(parsePromise)
  const isMounted = useIsMountedRef()
  const [currentWorkbook, updateWorkbook] = useState<CurrentWorkbook | null>(null)
  const [importOptions, setImportOptions] = useState(defaultImportOptions)
  const currentClient = useCurrentClient()
  const sheet = currentWorkbook && currentWorkbook.sheets[currentWorkbook.selectedSheetName]
  const apolloClient = useApolloClient()

  const nav = useNavigate()
  const navRef = useRef(nav)
  navRef.current = nav

  useEffect(() => {
    if (file) {
      console.log('parseFile', file)
      setParsePromise(
        parseXLSFile(file) // parse from binary to xlsx.js format
          .then(parseXLSJSWorkbook) // extract raw values
          .then(res =>
            guessFormat(res, currentClient.id, apolloClient).then(sheetConfig => {
              if (isMounted.current) {
                setTimeout(() => {
                  navRef.current('/import_casecards/preview')
                })
              }
              return [res, sheetConfig]
            })
          )
      )
    }
  }, [file, isMounted, currentClient.id, apolloClient])

  useEffect(() => {
    if (parsePromiseState.result) {
      const [workbook, sheetConfig] = parsePromiseState.result
      updateWorkbook(extractFormat(sheetConfig, workbook, importContext))
      setImportOptions(sheetFormat2ImportOptions[sheetConfig.format])
    }
  }, [parsePromiseState.result, importContext])

  const setFormat = useCallback(
    (format: SheetFormat) => {
      if (parsePromiseState.result) {
        updateWorkbook(extractFormat({ format, columnTypes: [] }, parsePromiseState.result[0], importContext))
        setImportOptions(sheetFormat2ImportOptions[format])
      }
    },
    [parsePromiseState.result, updateWorkbook, importContext]
  )

  const updateImportOptions = useCallback(
    (options: Partial<ImportOptions>) => {
      const opts = { ...options }
      if (opts.generateReminders || opts.markOldInvoicesAsPaid) {
        opts.importCasecards = true
      }
      if (opts.importCasecards === false) {
        opts.generateReminders = false
        opts.markOldInvoicesAsPaid = false
      }
      setImportOptions({ ...importOptions, ...opts })
    },
    [importOptions]
  )

  const parseError: Error | undefined = useMemo(() => {
    if (!parsePromiseState.error || parsePromiseState.error instanceof Error) {
      return parsePromiseState.error as Error | undefined
    }
    return new Error(String(parsePromiseState.error))
  }, [parsePromiseState.error])

  const columns = sheet?.columns
  const format = currentWorkbook?.format

  // @TODO extract to hook
  const generalErrors: string[] = useMemo(() => {
    if (columns) {
      const errors: string[] = []
      const columnTypeList = columns.map(col => col.type)
      const requiredColumns = importOptions.importCasecards
        ? columnsRequiredForInvoiceImport[format ?? SheetFormat.simple]
        : [ColumnType.companyName]
      const missingColumns = requiredColumns.filter(ct => !columnTypeList.includes(ct))
      if (missingColumns.length) {
        errors.push(`Columns ${formatArrayReadable(missingColumns.map(ct => columnTypeNames[ct]))} are required.`)
      }
      // if (importOptions.importInvoices) {
      //   if (!(columnTypeList.includes(ColumnType.principalDebt) || columnTypeList.includes(ColumnType.linePrice))) {
      //     errors.push(
      //       `Either "${columnTypeNames[ColumnType.principalDebt]}" or "${columnTypeNames[ColumnType.linePrice]}" is required.`
      //     )
      //   }
      //   if (!columnTypeList.includes(ColumnType.documentNumber) && !currentClient.invoice_series) {
      //     errors.push(
      //       `Either "${
      //         columnTypeNames[ColumnType.documentNumber]
      //       }" column must exist, or client must have invoice series set to generate a document number.`
      //     )
      //   }
      //   if (!columnTypeList.includes(ColumnType.dueDate) && !currentClient.category?.invoice_due_days) {
      //     errors.push(
      //       `Either "${
      //         columnTypeNames[ColumnType.dueDate]
      //       }" column must exist, or client default category must have "Invoice due in days" specified.`
      //     )
      //   }
      //   if (!columnTypeList.includes(ColumnType.dueDate) && !columnTypeList.includes(ColumnType.documentDate)) {
      //     errors.push(
      //       `If "${columnTypeNames[ColumnType.dueDate]}" is not specified, "${columnTypeNames[ColumnType.documentDate]}" must exist.`
      //     )
      //   }
      // }
      return errors
    }
    return []
  }, [columns, format, importOptions, currentClient])

  // const generalWarnings = useMemo((): string[] => {
  //   const warnings: string[] = []
  //   if (columns) {
  //     const columnTypeList = columns.map(col => col.type)
  //     if (importOptions.importInvoices && !columnTypeList.includes(ColumnType.documentNumber)) {
  //       warnings.push(
  //         `Because no document number is defined, all invoices will be imported as new with generated document numbers in series ${currentClient.invoice_series} starting with number ${currentClient.next_invoice_number}.`
  //       )
  //     }
  //   }
  //   return warnings
  // }, [importOptions, columns, currentClient])

  const errorCount: number = useMemo(
    () => (sheet?.errors ? R.sum(sheet.errors.map(row => R.sum(row.map(col => col.length)))) : 0),
    [sheet?.errors]
  )

  const isValid = (sheet && sheet.rows.length && !errorCount && !generalErrors.length) || false

  // sanity check, no point in proceeding if client has no current category
  if (!currentClient.category_id) {
    return (
      <InsetContainer>
        <Container>
          <Alert
            type='warning'
            showIcon={true}
            message={`Client "${currentClient.name}" needs to be assigned default category before any invoices can be imported.`}
            description={
              <>
                <p>You can do so by following these steps:</p>
                <ol>
                  <li>
                    <Link to='/templates/reminders/new'>Create a reminder template</Link>
                  </li>
                  <li>
                    <Link to='/categories/new'>Create a category</Link>
                  </li>
                  <li>
                    <Link to={`/clients/${currentClient.id}`}>Assign this category as "Default category" to current client</Link>
                  </li>
                </ol>
              </>
            }
          />
        </Container>
      </InsetContainer>
    )
  }

  return (
    <InsetContainer>
      <Container $centeredColumn={true}>
        <ImportBreadcrumbs hasFile={!!parsePromiseState.result} isValid={isValid} />
        {parsePromiseState.loading ? (
          <Spinner noDelay={true} tip='Reading excel file...' />
        ) : (
          <Routes>
            <Route
              path='/'
              element={
                <PayupDropzone
                  file={file}
                  onUpload={files => files.length && setFile(files[0])}
                  parseError={parseError}
                  typeName='excel'
                  acceptedMimeTypes={ACCEPTED_MIME_TYPES}
                  iconSrc={excelImg}
                />
              }
            />
            {parsePromiseState.result &&
              (currentWorkbook ? (
                <Route
                  path='/preview'
                  element={
                    <CasecardImportPreviewStage
                      setFormat={setFormat}
                      workbook={currentWorkbook}
                      setWorkbook={updateWorkbook}
                      importOptions={importOptions}
                      updateImportOptions={updateImportOptions}
                      errorCount={errorCount}
                      generalErrors={generalErrors}
                      generalWarnings={[]}
                      isValid={isValid}
                      importContext={importContext}
                    />
                  }
                />
              ) : null)}
            {isValid && sheet && currentWorkbook && file && parsePromiseState.result && (
              <Route
                path='/confirm'
                element={
                  <CasecardImportConfirmStage
                    importContext={importContext}
                    file={file}
                    raw={parsePromiseState.result[0]}
                    sheet={sheet}
                    importOptions={importOptions}
                    initialSheetConfig={parsePromiseState.result[1]}
                  />
                }
              />
            )}
            <Route path='*' element={<Navigate to='/import_casecards' />} />
          </Routes>
        )}
      </Container>
    </InsetContainer>
  )
}

export const CasecardImportView: FC = () => (
  <Fetch<GetImportContext, GetImportContextVariables>
    query={GetImportContextQuery}
    variables={{ client_id: useCurrentClient().id }}
    fetchPolicy='network-only'
  >
    {data => <ImportViewInner importContext={data} />}
  </Fetch>
)
