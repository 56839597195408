import { Plan, CasecardFields, ReminderFields } from '@/models/casecard_import/plan'
import { useCallback, useState } from 'react'
import { uploadFile } from '@/utils/fetch'
import { useApolloClient } from 'react-apollo'
import {
  InsertImportFileMutation,
  ImportInsertCasecardsMutation,
  ImportInsertDebtorContactsMutation,
  ImportInsertDebtorsMutation,
  ImportUpdateDebtorMutation,
  UpsertImportFileColumnConfigMutation
} from '@/queries/import.queries'
import { InsertImportFile, InsertImportFileVariables } from '@/queries/_gen_/InsertImportFile'
import { RawWorkbook } from '@/models/casecard_import/xls'
import { useCurrentClient } from '../useCurrentClient'
import { debtor_insert_input, casecard_insert_input, reminder_insert_input } from '@/queries/_gen_global'
import * as R from 'ramda'
import { ReminderStatus } from '@/models/reminders'
import { GraphQLError } from 'graphql'
import { FetchResult } from 'apollo-link'
import { ClientWithId } from '@/models/client'
import { useCurrentUserId } from '../useCurrentUsername'
import combineQuery, { CombinedQueryBuilder } from 'graphql-combine-query'
import { Folder } from 'common/models/files'
import { logger } from '@/logger'
import { ImportInsertDebtorsVariables, ImportInsertDebtors } from '@/queries/_gen_/ImportInsertDebtors'
import { ImportInsertCasecards, ImportInsertCasecardsVariables } from '@/queries/_gen_/ImportInsertCasecards'
import { ImportInsertDebtorContacts, ImportInsertDebtorContactsVariables } from '@/queries/_gen_/ImportInsertDebtorContacts'
import { ImportUpdateDebtorVariables } from '@/queries/_gen_/ImportUpdateDebtor'
import { UpsertImportFileColumnConfig, UpsertImportFileColumnConfigVariables } from '@/queries/_gen_/UpsertImportFileColumnConfig'

type ImportFn = (plan: Plan, file: File, raw: RawWorkbook) => void

export class ImportError extends Error {
  constructor(
    message: string,
    // eslint-disable-next-line prettier/prettier
    private graphqlErrors?: GraphQLError[]
  ) {
    super(message)
  }

  get message(): string {
    return [this.message, (this.graphqlErrors || []).map(err => err.message)].join(' ')
  }

  static fromResult(message: string, result: FetchResult<unknown>): ImportError {
    return new ImportError(message, (result.errors || []).slice())
  }
}

// function castDate(dt: Date | null | string | undefined): string | null | undefined {
//   if (typeof dt === 'string') {
//     return dt
//   }
//   return dt ? toBackendDate(dt) : dt
// }

// Map and format extra fields
export function createMutation(plan: Plan, client: ClientWithId, importFileId: number): CombinedQueryBuilder {
  const toCasecardInput = (casecardFields: CasecardFields): casecard_insert_input => {
    const { ...restCasecardFields } = casecardFields
    return {
      ...restCasecardFields
    }
  }

  const toReminderInput = ({
    template,
    reminder_type,
    reminder_period,
    is_suspended,
    period_days_from,
    period_days_to
  }: ReminderFields): reminder_insert_input => ({
    client_id: client.id,
    template_id: template ? template.id : null,
    type: reminder_type,
    import_file_id: importFileId,
    period: reminder_period,
    status: is_suspended ? ReminderStatus.suspended : ReminderStatus.new,
    period_days_from,
    period_days_to
  })

  // insert contacts
  let mut = combineQuery('Import').add<ImportInsertDebtorContacts, ImportInsertDebtorContactsVariables>(
    ImportInsertDebtorContactsMutation,
    {
      insert_contacts: R.flatten(
        plan.update_debtors.map(d => d.new_contacts.map(v => ({ ...v, debtor_id: d.id, import_file_id: importFileId })))
      )
    }
  )

  // insert new debtors
  mut = mut.add<ImportInsertDebtors, ImportInsertDebtorsVariables>(ImportInsertDebtorsMutation, {
    insert_debtors: plan.new_debtors.map(
      ({ contacts, casecards, reminders, ...debtorFields }): debtor_insert_input => ({
        ...debtorFields,
        client_id: client.id,
        import_file_id: importFileId,
        casecards: {
          data: casecards.map(toCasecardInput)
        },
        invoices: {
          data: []
        },
        reminders: {
          data: reminders.map(toReminderInput)
        },
        debtor_contacts: {
          data: contacts.map(c => ({ ...c, import_file_id: importFileId }))
        }
      })
    )
  })

  // insert casecards for existing debtors
  if (plan.import_options.importCasecards) {
    mut = mut.add<ImportInsertCasecards, ImportInsertCasecardsVariables>(ImportInsertCasecardsMutation, {
      insert_casecards: R.flatten(
        plan.update_debtors.map(debtor =>
          [...debtor.new_casecards, ...debtor.update_casecards].map(casecardFields => ({
            ...toCasecardInput(casecardFields),
            debtor_id: debtor.id
          }))
        )
      )
    })
  }

  // update debtors if needed
  const toBeUpdatedDebtors = plan.update_debtors.filter(deb => Object.keys(deb.update_fields).length > 0)
  if (toBeUpdatedDebtors.length) {
    mut = mut.addN<ImportUpdateDebtorVariables>(
      ImportUpdateDebtorMutation,
      toBeUpdatedDebtors.map(deb => ({
        update_debtor_debtor_id: deb.id,
        update_debtor_payload: deb.update_fields
      }))
    )
  }

  // update column types
  if (plan.update_column_types) {
    mut = mut.add<UpsertImportFileColumnConfig, UpsertImportFileColumnConfigVariables>(UpsertImportFileColumnConfigMutation, {
      config: JSON.stringify(plan.update_column_types),
      cc_client_id: client.id,
      column_count: plan.update_column_types.length
    })
  }

  return mut
}

export function useImport(): [ImportFn, Promise<{ importFileId: number }> | null] {
  const [promise, setPromise] = useState<Promise<{ importFileId: number }> | null>(null)

  const apolloClient = useApolloClient()

  const client = useCurrentClient()
  const user = useCurrentUserId()

  const importfn: ImportFn = useCallback(
    (plan: Plan, file: File, raw: RawWorkbook) => {
      // 1. upload the import file
      logger.info(`uploading import file [${file.name}]...`)
      const prom: Promise<{ importFileId: number }> = uploadFile(Folder.importfiles, client.id, file, file.name)
        // 2. create import entity in db
        .then(uploadResult => {
          logger.info('import file uploaded, inserting import to db...')
          return apolloClient.mutate<InsertImportFile, InsertImportFileVariables>({
            mutation: InsertImportFileMutation,
            variables: {
              bucket_filename: uploadResult.filename,
              orig_filename: file.name,
              import_options: plan.import_options,
              client_id: client.id,
              raw_data: raw,
              import_type: 'INVOICES'
            }
          })
        })

        // 3. create debtors, invoices, contacts & reminders
        .then(impFileRes => {
          const data = impFileRes.data
          if (data && data.insert_import_file && data.insert_import_file.returning.length) {
            logger.info('import file inserted, inserting debtors, contacts, reminders & invoices...')
            const result = data.insert_import_file.returning[0]
            const importFileId = result.id
            // const { document, variables } = createMutation(plan, client, user, importFileId)
            const { document, variables } = createMutation(plan, client, importFileId)
            return apolloClient.mutate({ mutation: document, variables }).then(() => importFileId)
          } else {
            logger.info(`failed to import file :( [${file.name}], errors=[${JSON.stringify(impFileRes.errors)}]`)
            throw ImportError.fromResult('Failed to save import file.', impFileRes)
          }
        })
        .then(importFileId => ({ importFileId }))
        .catch(e => {
          if (e instanceof ImportError) {
            throw e
          }
          logger.error('Unexpected failure while importing', e)
          throw new ImportError(e.message, [])
        })
      setPromise(prom)
    },
    [client, user, apolloClient]
  )

  return [importfn, promise]
}
