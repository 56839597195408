import { Sheet, ColumnType, SheetConfig } from '@/models/casecard_import/xls'
import {
  ContactFields,
  CasecardFields,
  // ReminderFields,
  Plan,
  isSameDebtor,
  UpdateDebtor,
  ImportOptions,
  NewDebtor,
  ImportContext
} from '@/models/casecard_import/plan'
import { sheetToValues } from '@/utils/casecard_xls'
import { useMemo } from 'react'
import { RowValues, parseSeparatedItems } from '@/views/casecard_import/columns'
import ApolloClient from 'apollo-client'
import { useApolloClient } from 'react-apollo'
import * as R from 'ramda'
import { GetExistingDebtors, GetExistingDebtorsVariables } from '@/queries/_gen_/GetExistingDebtors'
import { GetExistingDebtorsAndCasecardsQuery, GetExistingDebtorsQuery } from '@/queries/import.queries'
// import { GetExistingDebtorsQuery, GetExistingDebtorsAndInvoicesQuery } from '@/queries/import.queries'
import { extractQueryResult } from '@/utils/apollo'
import { useCurrentClient } from '../useCurrentClient'
import { ClientWithId } from '@/models/client'
import { ContactType, Debtor } from '@/models/debtor'
import {
  GetExistingDebtorsAndCasecards,
  GetExistingDebtorsAndCasecardsVariables,
  GetExistingDebtorsAndCasecards_debtor_casecards
} from '@/queries/_gen_/GetExistingDebtorsAndCasecards'
// import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
// import { ReminderType } from '@/models/reminders'
import { logger } from '@/logger'
// import { addDays } from 'date-fns'
// import { reminderType2HasPeriod, ReminderPeriod } from 'common/models/reminder'

// @TODO this could use further rewrite:
// first build a map debtor: row[], then go from there

function assertval<T>(what: T | undefined, message?: string): T {
  if (what === undefined) {
    throw new Error(message || 'unexpectedly undefined value')
  }
  return what
}

type ImportData = Map<Debtor, RowValues[]>

function valuesToImportData(values: RowValues[], importContext: ImportContext): ImportData {
  console.log(importContext) // TODO: remove importContext if not necessary
  const importData: ImportData = new Map()
  const debtors: Debtor[] = []

  values.forEach(row => {
    const name = assertval(row.debtor_code, 'there`s a row with no company name OR first name + last name')
    // const category_id: number | null = (() => {
    //   if (row.debtor_category) {
    //     return assertval(
    //       importContext.clientCategories.find(cat => cat.name === row.debtor_category)?.id,
    //       `category "${row.debtor_category}" not found`
    //     )
    //   }
    //   return null
    // })()
    const debtor: Debtor = {
      company_code: row.debtor_code || null,
      name,
      reference_code: null,
      vat_code: null,
      category_id: null,
      suspend_reminders_until: null,
      customer_code_in_client_system: null,
      extra_1: null,
      extra_2: null,
      penalty_type: null,
      penalty_amount: null,
      commission_type: null,
      commission_amount: null
    }

    const existingDebtor = debtors.find(existing => isSameDebtor(debtor, existing))
    if (existingDebtor) {
      ;(importData.get(existingDebtor) as RowValues[]).push(row)
    } else {
      importData.set(debtor, [row])
      debtors.push(debtor)
    }
  })
  return importData
}

function extractContacts(rows: RowValues[]): ContactFields[] {
  const contacts: ContactFields[] = []
  rows.map(row => {
    const address = row[ColumnType.debtorAddress]
    if (address) {
      contacts.push({ type: ContactType.address, value: address, city: row.debtor_city, post_code: null, country: null })
    }
    ;[...parseSeparatedItems(row[ColumnType.debtorPhoneNumber] ?? '')].forEach(value => {
      contacts.push({ type: ContactType.phone, value })
    })
    ;[...parseSeparatedItems(row[ColumnType.debtorEmail] ?? '')].forEach(value => {
      contacts.push({ type: ContactType.email, value })
    })
  })
  return R.uniqWith((a, b) => a.type === b.type && a.value?.toLowerCase() === b.value?.toLowerCase(), contacts)
}

// function dedupeReminders(reminders: ReminderFields[]): ReminderFields[] {
//   // sort reminders descending based on days_to
//   const sorted = R.sort((a, b) => b.period_days_to - a.period_days_to, reminders)
//   // pick out unique reminders based on reminder type and reminder period.
//   // if there are several, first one is picked, with latest "days_to" (most severe)
//   return R.uniqWith(
//     (x, y) =>
//       x.reminder_type === y.reminder_type &&
//       y.reminder_period === x.reminder_period &&
//       !(
//         x.reminder_period === ReminderPeriod.notLate &&
//         (x.period_days_to !== y.period_days_to || x.period_days_from !== y.period_days_from)
//       ),
//     sorted
//   )
// }

/*
adds to plan debtors and contacts that should be inserted/updated
*/
async function createDebtorsAndContactsPlan(
  importOptions: ImportOptions,
  importData: ImportData,
  apolloClient: ApolloClient<any>,
  currentClient: ClientWithId
): Promise<Plan> {
  const importDebtors = Array.from(importData.entries())

  const result = extractQueryResult(
    await apolloClient.query<GetExistingDebtors, GetExistingDebtorsVariables>({
      query: GetExistingDebtorsQuery,
      variables: {
        debtor_company_codes: importDebtors.map(([debtor]) => debtor.company_code).filter((code): code is string => !!code),
        client_id: currentClient.id,
        debtor_names: importDebtors.map(([debtor]) => debtor.name)
      },
      fetchPolicy: 'no-cache'
    })
  )

  const plan: Plan = {
    import_options: importOptions,
    new_debtors: [],
    update_debtors: [],
    next_invoice_number: currentClient.next_invoice_number
  }

  importDebtors.forEach(([debtor, rows]) => {
    const contacts = extractContacts(rows)

    // sanity check: do not allow import if debtor with same name but different company code already exists in database
    if (debtor.company_code && debtor.name) {
      const existingWithSameNameButDiffCode = result.debtor.find(
        rdebtor => rdebtor.company_code && rdebtor.name && rdebtor.name === debtor.name && rdebtor.company_code !== debtor.company_code
      )
      if (existingWithSameNameButDiffCode) {
        throw new Error(
          `Trying to import debtor "${debtor.name}" with company code ${debtor.company_code}, but a debtor with this name and different company code ${existingWithSameNameButDiffCode.company_code} already exists in the database.`
        )
      }
    }

    const existingDebtor = result.debtor.find(rdebtor => isSameDebtor(debtor, rdebtor))
    if (existingDebtor) {
      const update_fields: UpdateDebtor['update_fields'] = {}
      if (debtor.category_id && debtor.category_id !== existingDebtor.category_id) {
        update_fields.category_id = debtor.category_id
      }
      if (debtor.customer_code_in_client_system && debtor.customer_code_in_client_system != existingDebtor.customer_code_in_client_system) {
        update_fields.customer_code_in_client_system = debtor.customer_code_in_client_system
      }
      if (debtor.extra_1 && debtor.extra_1 !== existingDebtor.extra_1) {
        update_fields.extra_1 = debtor.extra_1
      }
      if (debtor.extra_2 && debtor.extra_2 !== existingDebtor.extra_2) {
        update_fields.extra_2 = debtor.extra_2
      }
      if (debtor.company_code && !existingDebtor.company_code) {
        update_fields.company_code = debtor.company_code
      }
      plan.update_debtors.push({
        id: existingDebtor.id,
        name: existingDebtor.name,
        company_code: debtor.company_code || existingDebtor.company_code,
        new_contacts: contacts.filter(
          c => !existingDebtor.debtor_contacts.find(ec => ec.type === c.type && ec.value.toLowerCase() === c.value?.toLowerCase())
        ),
        new_casecards: [],
        update_casecards: [],
        new_reminders: [],
        update_fields
      })
    } else {
      plan.new_debtors.push({
        ...debtor,
        contacts,
        casecards: [],
        reminders: [],
        category_id: debtor.category_id || undefined
      })
    }
  })
  return plan
}

function fillInUndefinedCasecardFields(
  casecard: CasecardFields,
  existing: GetExistingDebtorsAndCasecards_debtor_casecards
): CasecardFields {
  return Object.keys(casecard).reduce<CasecardFields>(
    (obj, key) => ({
      ...obj,
      [key]: (casecard as any)[key] ?? (existing as any)[key]
    }),
    {} as CasecardFields
  ) as CasecardFields
}

// function getCategory(
//   existing: GetExistingDebtorsAndInvoices,
//   debtor: Debtor,
//   context: ImportContext
// ): GetExistingDebtorsAndInvoices_debtor_category {
//   const existingDebtor = existing.debtor.find(rdebtor => isSameDebtor(rdebtor, debtor))
//   if (debtor.category_id) {
//     return assertval(
//       context.clientCategories.find(cat => cat.id === debtor.category_id),
//       `failed to find category id=${debtor.category_id}`
//     )
//   }
//   return assertval((existingDebtor && existingDebtor.category) || (existing.client_by_pk && existing.client_by_pk.category) || undefined)
// }

function withCasecards(
  plan: Plan,
  importData: ImportData,
  existing: GetExistingDebtorsAndCasecards,
  currentClient: ClientWithId
  // context: ImportContext
): Plan {
  function row2Casecard(row: RowValues, debtor: Debtor): CasecardFields | null {
    console.log(debtor) // TODO: remove if debtor is not being used
    const amountOutstanding = row[ColumnType.principalDebt]

    if (amountOutstanding !== undefined && amountOutstanding.lessThanOrEqualTo(0)) {
      logger.info(`skipping document ${row[ColumnType.caseNumber]}, no outstanding amount`)
      return null
    }

    // const invoiceLines: InvoiceLineItemFields[] = []

    // if (row[ColumnType.lineProductName] && row[ColumnType.linePrice]) {
    //   invoiceLines.push({
    //     name: row[ColumnType.lineProductName],
    //     price: row[ColumnType.linePrice],
    //     quantity: row[ColumnType.lineQuantity] || 1,
    //     product_id: row[ColumnType.lineProductId]
    //   })
    // }

    // const due_date = (() => {
    //   if (row[ColumnType.dueDate]) {
    //     return row[ColumnType.dueDate]
    //   }
    //   const documentDate = assertval(row[ColumnType.documentDate], 'Expected document date to exist, because due date does not.')
    //   const category = getCategory(existing, debtor, context)
    //   const dueInDays = assertval<number>(
    //     category.invoice_due_days || existing.client_by_pk?.category?.invoice_due_days || undefined,
    //     '"invoice due in days" does not exist on debtor or client category, but expected to calculate due date"'
    //   )
    //   return addDays(documentDate, dueInDays)
    // })()

    const client_cost = 0

    // add casecaerd
    // TODO: Fix casecard mapping
    return {
      case_number: row[ColumnType.caseNumber],
      amount_outstanding: amountOutstanding,
      client_cost,
      commission_amount: null,
      commission_type: null,
      currency: row[ColumnType.currency],
      is_paid: false,
      penalty_amount: null,
      penalty_type: null
    }
  }

  let nextInvoiceNumber = plan.next_invoice_number

  function processNewCasecard(casecard: CasecardFields): CasecardFields {
    //  for new casecard we want to set outstanding amt to line price, if there is none
    const cc = {
      ...casecard
    }

    // generate document number if there isn't one
    if (!cc.case_number) {
      assertval(currentClient.invoice_series, 'Current client has no invoice series defined, unable to generate invoice numbers.')
      cc.case_number = `${currentClient.invoice_series}-${nextInvoiceNumber++}` // TODO: Define if case number can be auto generated
    }
    return cc
  }

  const importDebtors = Array.from(importData.keys())

  const new_debtors: NewDebtor[] = plan.new_debtors.map(debtor => {
    const importDebtor = assertval(importDebtors.find(idebtor => isSameDebtor(debtor, idebtor)))
    const rows = assertval(importData.get(importDebtor))

    const casecards = rows
      .map(casecard => row2Casecard(casecard, importDebtor))
      .filter((cc): cc is CasecardFields => !!cc)
      .map(processNewCasecard)
    return {
      ...debtor,
      casecards
    }
  })

  // const update_debtors: UpdateDebtor[] = []
  const update_debtors: UpdateDebtor[] = plan.update_debtors.map(debtor => {
    const importDebtor = assertval(
      importDebtors.find(idebtor => isSameDebtor(debtor, idebtor)),
      'import debtor not found'
    )
    const rows = assertval(importData.get(importDebtor), 'row for import debtor not found')
    const existingDebtor = assertval(
      existing.debtor.find(rdebtor => isSameDebtor(rdebtor, debtor)),
      'existing debtor not found'
    )
    const casecards = rows.map(casecard => row2Casecard(casecard, importDebtor)).filter((cc): cc is CasecardFields => !!cc)

    const retv: UpdateDebtor = {
      ...debtor,
      new_casecards: [],
      update_casecards: []
    }

    casecards.forEach(casecard => {
      const existingCasecard = existingDebtor && existingDebtor.casecards.find(cc => cc.case_number === casecard.case_number)
      if (existingCasecard) {
        retv.update_casecards.push({
          ...fillInUndefinedCasecardFields(casecard, existingCasecard),
          id: existingCasecard.id
        })
      } else {
        retv.new_casecards.push(R.omit(['id'], processNewCasecard(casecard)))
      }
    })

    return retv
  })

  return {
    ...plan,
    new_debtors,
    update_debtors,
    next_invoice_number: nextInvoiceNumber
  }
}

// function withReminders(plan: Plan, importData: ImportData, existing: GetExistingDebtorsAndInvoices, context: ImportContext): Plan {
//   function invoice2reminders(invoice: CasecardFields, debtor: Debtor, isSuspended: boolean): ReminderFields[] {
//     const reminders: ReminderFields[] = []
//     // possibly generate reminder
//     const daysPast = differenceInCalendarDays(new Date(), invoice.due_date)
//     const category = getCategory(existing, debtor, context)
//     category.category_steps.forEach(categoryStep => {
//       if (categoryStep.days_from <= daysPast && categoryStep.days_to >= daysPast) {
//         const reminderType = categoryStep.reminder_type as ReminderType
//         const period: ReminderPeriod = (() => {
//           if (!reminderType2HasPeriod[reminderType]) {
//             return ReminderPeriod.all
//           } else if (categoryStep.days_to <= 0) {
//             return ReminderPeriod.notLate
//           }
//           return ReminderPeriod.late
//         })()
//         reminders.push({
//           template: categoryStep.template,
//           reminder_type: reminderType,
//           reminder_period: period,
//           period_days_to: categoryStep.days_to,
//           period_days_from: categoryStep.days_from,
//           is_suspended: isSuspended
//         })
//       }
//     })
//     return reminders
//   }

//   const importDebtors = Array.from(importData.keys())

//   return {
//     ...plan,
//     new_debtors: plan.new_debtors.map(debtor => {
//       const importDebtor = assertval(importDebtors.find(idebtor => isSameDebtor(debtor, idebtor)))
//       return {
//         ...debtor,
//         reminders: dedupeReminders(debtor.invoices.map(inv => invoice2reminders(inv, importDebtor, false)).flat())
//       }
//     }),
//     update_debtors: plan.update_debtors.map(debtor => {
//       const importDebtor = assertval(importDebtors.find(idebtor => isSameDebtor(debtor, idebtor)))
//       const existingDebtor = assertval(existing.debtor.find(rdebtor => isSameDebtor(rdebtor, debtor)))
//       const isSuspended = Boolean(existingDebtor && existingDebtor.are_reminders_suspended)
//       const reminders: ReminderFields[] = []
//       debtor.update_invoices.forEach(invoice => {
//         const existingInvoice = existingDebtor && existingDebtor.invoices.find(inv => inv.case_number === invoice.case_number)
//         if (existingInvoice?.is_sending_suspended !== true) {
//           reminders.push(...invoice2reminders(invoice, importDebtor, isSuspended))
//         }
//       })
//       debtor.new_invoices.forEach(invoice => {
//         reminders.push(...invoice2reminders(invoice, importDebtor, isSuspended))
//       })
//       return {
//         ...debtor,
//         new_reminders: dedupeReminders(reminders)
//       }
//     })
//   }
// }

export function usePlan(
  sheet: Sheet,
  importOptions: ImportOptions,
  context: ImportContext,
  initialSheetConfig: SheetConfig,
  dependencies: unknown[]
): Promise<Plan> {
  const apolloClient = useApolloClient()
  const currentClient = useCurrentClient()

  return useMemo(async () => {
    const values = sheetToValues(sheet)
    const importData = valuesToImportData(values, context)

    let plan = await createDebtorsAndContactsPlan(importOptions, importData, apolloClient, currentClient)

    if (importOptions.importCasecards) {
      const caseNumbers = sheet.columns.find(c => c.type === ColumnType.caseNumber)
        ? R.flatten(Array.from(importData.values())).map(row => assertval(row.case_number))
        : []
      const existing = extractQueryResult(
        await apolloClient.query<GetExistingDebtorsAndCasecards, GetExistingDebtorsAndCasecardsVariables>({
          query: GetExistingDebtorsAndCasecardsQuery,
          variables: {
            client_id: currentClient.id,
            debtor_company_codes: plan.update_debtors.map(debtor => debtor.company_code).filter((code): code is string => !!code),
            casecaerd_case_numbers: caseNumbers,
            debtor_names: plan.update_debtors.map(debtor => debtor.name)
          },
          fetchPolicy: 'no-cache'
        })
      )
      if (importOptions.importCasecards) {
        plan = withCasecards(plan, importData, existing, currentClient)
        // plan = withInvoices(plan, importData, existing, currentClient, context)
        // if (importOptions.generateReminders) {
        //   plan = withReminders(plan, importData, existing, context)
        // }
      }
    }
    if (JSON.stringify(initialSheetConfig.columnTypes) !== JSON.stringify(sheet.columns.map(c => c.type))) {
      plan.update_column_types = sheet.columns.map(c => c.type)
    }
    return plan
  }, [sheet, importOptions, initialSheetConfig, ...dependencies, apolloClient, currentClient, context])
}
