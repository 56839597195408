import XLSX, { WorkSheet, utils, WorkBook } from 'xlsx'
import {
  Sheet,
  RawWorkbook,
  RawSheet,
  SheetFormat,
  CurrentWorkbook,
  SheetColumn,
  ColumnType,
  SheetConfig
} from '@/models/casecard_import/xls'
import { columnTypes, RowValues, parseDate } from '@/views/casecard_import/columns'
import * as R from 'ramda'
import Decimal from 'decimal.js'
import { arrayrepl } from './array'
import { logger } from '@/logger'
import { ImportContext } from '@/models/casecard_import/plan'
import ApolloClient from 'apollo-client'
import { GetImportFileColumnConfigQuery } from '@/queries/import.queries'
import { GetImportFileColumnConfig, GetImportFileColumnConfigVariables } from '@/queries/_gen_/GetImportFileColumnConfig'

export function parseXLSFile(file: File): Promise<XLSX.WorkBook> {
  /*
      @TODO
      This POC level, to make it prod ready:
      1) handle errors
      2) move xls parsing to worker thread
      3) eat cake
      4) test with all browsers / multiple xls versions
  */
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      try {
        const rawData = e.target.result
        resolve(XLSX.read(rawData, { type: 'binary', cellText: false, dateNF: 'YYYY-MM-DD', cellDates: true }))
      } catch (e) {
        logger.error('Error parsing xls file', e as Error)
        reject(e)
      }
    }
    reader.onerror = (e: ProgressEvent) => {
      logger.error(`Error reading xls file, ${e}`)
      reject(e)
    }
    setTimeout(() => reader.readAsBinaryString(file), 100)
  })
}

function removeNullUnicodeChars(x: unknown): unknown {
  if (typeof x === 'string') {
    return x.replace(/\0/g, '')
  }
  return x
}

export function parseXLSJSSheet(sheet: WorkSheet, name: string): RawSheet {
  const data = utils.sheet_to_json(sheet, { header: 1, raw: false, dateNF: 'YYYY-MM-DD' })
  const rows = Array.from(data as unknown[][])
    .filter(row => row && row.find(x => x !== undefined && x !== null))
    .map(row => Array.from(row || []))
  const maxRowLength = rows.reduce((maxlen, row) => Math.max(maxlen, row.length), 0)
  return {
    name,
    rows: rows.map(row => R.range(0, maxRowLength).map((idx: any) => removeNullUnicodeChars(row[idx]))) // pad each row to longest row
  }
}

export function parseXLSJSWorkbook(workbook: WorkBook): RawWorkbook {
  return {
    sheets: workbook.SheetNames.slice()
      .sort()
      .map((sheetName: any) => parseXLSJSSheet(workbook.Sheets[sheetName], sheetName))
  }
}

const label2ColumnType: Record<string, ColumnType> = {
  'Case Number': ColumnType.caseNumber,
  'Company Code': ColumnType.companyCode,
  'Company Name': ColumnType.companyName,
  Address: ColumnType.debtorAddress,
  City: ColumnType.debtorCity,
  'Debtor Phone Number': ColumnType.debtorPhoneNumber,
  'Borrower Phone Number': ColumnType.borrowerPhoneNumber,
  'Invoice Date': ColumnType.invoiceDate,
  'Invoice Payment Date': ColumnType.invoicePaymentDate,
  Currency: ColumnType.currency,
  'Principal Debt': ColumnType.principalDebt,
  'Debtor Email': ColumnType.debtorEmail,
  'Interest Rate': ColumnType.interestRate
}

export function simpleExtractor(sheet: RawSheet): [SheetColumn[], unknown[][]] {
  /*
  simple sheet with header, no transform.
  tries to guess column type based on label
  */
  const columnTypesUsed = new Set<ColumnType>()
  const columnTypes: ColumnType[] = (sheet.rows.length ? sheet.rows[0] : []).map(c => {
    const label = String(c)
    let columnType = ColumnType.skip
    const columnTypeGuess = label2ColumnType[label]
    if (columnTypeGuess && !columnTypesUsed.has(columnTypeGuess)) {
      columnType = columnTypeGuess
      columnTypesUsed.add(columnTypeGuess)
    }
    return columnType
  })

  return customExtractor(sheet, columnTypes)
}

export function simpleExtractorNoHeader(sheet: RawSheet): [SheetColumn[], unknown[][]] {
  return customExtractor(sheet, [], true)
}

export function customExtractor(sheet: RawSheet, columnTypes: ColumnType[], noHeaders = false): [SheetColumn[], unknown[][]] {
  // pad out columns up to number of values in longest row
  const maxColumns = sheet.rows.reduce((len, row) => Math.max(len, row.length), 0)

  const columns: SheetColumn[] = (sheet.rows.length ? sheet.rows[0] : []).map((c, idx) => {
    const label = noHeaders ? '?' : String(c)
    return { label, type: columnTypes[idx] || ColumnType.skip }
  })

  while (columns.length < maxColumns) {
    columns.push({
      label: `Column ${columns.length + 1}`,
      type: ColumnType.skip
    })
  }
  const rows = noHeaders ? sheet.rows : sheet.rows.slice(1)
  return [columns, rows]
}

export function SILVAExtractor(sheet: RawSheet): [SheetColumn[], unknown[][]] {
  // hardcoded columns
  const columns = [
    { label: 'Kliento ref. Nr.', type: ColumnType.caseNumber },
    { label: 'Skolininko asm/įm.kodas', type: ColumnType.companyCode },
    { label: 'Įmonės pavadinimas/ Skolininko Vardas Pavardė', type: ColumnType.companyName },
    { label: 'Skolininko adresas', type: ColumnType.debtorAddress },
    { label: 'Skolininko miestas', type: ColumnType.debtorCity },
    { label: 'Skolininko telefonas', type: ColumnType.debtorPhoneNumber },
    { label: 'Faktūros numeris', type: ColumnType.borrowerPhoneNumber },
    { label: 'Faktūros išrašymo data', type: ColumnType.invoiceDate },
    { label: 'Faktūros apmokėjimo data', type: ColumnType.invoicePaymentDate },
    { label: 'Pagrindinė skola', type: ColumnType.principalDebt },
    { label: 'Skolininko el.paštas', type: ColumnType.debtorEmail },
    { label: 'Metinė palūkanų norma delspinigiams', type: ColumnType.interestRate }
  ]

  const rows = sheet.rows.map(row => row.filter(val => val !== undefined)).filter(row => row.length === 12 && row[0] !== columns[0].label)
  return [columns, rows]
}
export function UPRentExtractor(sheet: RawSheet): [SheetColumn[], unknown[][]] {
  // Order id	Subscription id	Ledger	Product id	Product description	User id	First name	Last name	Personal number	Adress	City	Land	Zipcode	Telephone	Email	Total	Text	Approved	Reason	Invoice date

  const columns = [{ label: 'Company Code', type: ColumnType.companyCode }]

  return [columns, sheet.rows.slice(1)]
}

export function BDExtractor(sheet: RawSheet): [SheetColumn[], unknown[][]] {
  const columns = [{ label: 'Company Code', type: ColumnType.companyCode }]

  const rows = sheet.rows.map(row => row.filter(val => val !== undefined))
  return [columns, rows]
}

export function SGBExtractor(sheet: RawSheet): [SheetColumn[], unknown[][]] {
  // Order id	Subscription id	Ledger	Product id	Product description	User id	First name	Last name	Personal number	Adress	City	Land	Zipcode	Telephone	Email	Total	Text	Approved	Reason	Invoice date

  const columns = [{ label: 'Company Code', type: ColumnType.companyCode }]

  return [columns, sheet.rows.slice(1)]
}

type SheetExtractor = (sheet: RawSheet, columnTypes: ColumnType[]) => [SheetColumn[], unknown[][]]

export const sheetExtractors: { [key in SheetFormat]: SheetExtractor } = {
  [SheetFormat.simpleNoHeader]: simpleExtractorNoHeader,
  [SheetFormat.simple]: simpleExtractor,
  [SheetFormat.silva]: SILVAExtractor,
  [SheetFormat.bd]: BDExtractor,
  [SheetFormat.sgb]: SGBExtractor,
  [SheetFormat.uprent]: UPRentExtractor,
  [SheetFormat.custom]: customExtractor
}

export const guessFormat = async (workbook: RawWorkbook, clientId: number, apolloClient: ApolloClient<unknown>): Promise<SheetConfig> => {
  if (workbook.sheets.length) {
    const sheet = workbook.sheets[0]
    const rawColumns = sheet.rows[0]
    if (sheet.rows[0] && String(sheet.rows[0][0]).includes('Kliento ref. Nr.')) {
      return { format: SheetFormat.silva }
    }
    if (
      sheet.rows[0] &&
      sheet.rows[0].length === 10 &&
      sheet.rows[0][0] !== undefined &&
      sheet.rows[0][1] === undefined &&
      sheet.rows[0][2] !== undefined &&
      sheet.rows[0][3] === undefined &&
      !isNaN(parseFloat(String(sheet.rows[0][9])))
    ) {
      return { format: SheetFormat.bd }
    }
    if (!R.without(['Ledger', 'Order id', 'Subscription id', 'First name', 'Last name', 'Zipcode', 'Land'], sheet.rows[0]).length) {
      return { format: SheetFormat.sgb }
    }

    const config = await apolloClient.query<GetImportFileColumnConfig, GetImportFileColumnConfigVariables>({
      query: GetImportFileColumnConfigQuery,
      variables: { column_count: rawColumns.length, client_id: clientId },
      fetchPolicy: 'network-only'
    })
    if (config.data?.import_file_column_config?.length) {
      return {
        format: SheetFormat.custom,
        columnTypes: JSON.parse(config.data?.import_file_column_config?.[0].config)
      }
    }

    if (
      !R.without(
        ['Kliento pavadinimas', '', 'Dokumento Nr.', 'Dok.data', 'Mok.data', 'Debetas', 'Kreditas', 'Dokumento suma', 'Vėlav. d.'],
        sheet.rows[0].map(x => x || '')
      ).length &&
      sheet.rows[0].length === 9
    ) {
      return { format: SheetFormat.uprent }
    }
  }
  return { format: SheetFormat.simple }
}

export const validateValue = (value: string, columnType: ColumnType, context: ImportContext): string[] => {
  const { validators, required } = columnTypes[columnType]
  if (required && !value) {
    return ['Required.']
  }
  if (value && validators && validators.length) {
    return R.flatten(validators.map(validator => validator(value, context)))
  }
  return []
}

const validateRows = (rows: string[][], columns: SheetColumn[], context: ImportContext): string[][][] =>
  rows.map(row => row.map((value, colIdx) => validateValue(value, columns[colIdx].type, context)))

// @TODO how to handle errors?..
export const extractFormat = (config: SheetConfig, workbook: RawWorkbook, context: ImportContext): CurrentWorkbook => ({
  selectedSheetName: workbook.sheets[0].name,
  format: config.format,
  sheets: workbook.sheets.reduce((map, sheet) => {
    const [columns, rows] = sheetExtractors[config.format](sheet, config.columnTypes || [])
    if (rows[0] && rows[0].length < columns.length) {
      throw new Error('Not enough columns in sheet format')
    }
    const stringifiedRows = rows.map(row =>
      row.map(value => {
        if (value === null || value === undefined) {
          return ''
        }
        return String(value)
      })
    )
    map[sheet.name] = {
      name: sheet.name,
      columns,
      rows: stringifiedRows,
      errors: complexValidation(
        stringifiedRows,
        validateRows(stringifiedRows, columns, context),
        columns.map(c => c.type)
      )
    }
    return map
  }, {} as { [key: string]: Sheet })
})

export function sheetToValues(sheet: Sheet): RowValues[] {
  const colType2Index = new Map(sheet.columns.map((col, idx) => [col.type, idx]))

  function getVal(row: string[], type: ColumnType): undefined | string {
    const idx = colType2Index.get(type)
    return idx !== undefined ? row[idx] : undefined
  }

  function parseVal<T>(row: string[], type: ColumnType, parser: (val: string) => T): T | undefined {
    const rawVal = getVal(row, type)
    if (rawVal === undefined || rawVal === '') {
      return undefined
    }
    return parser(rawVal)
  }

  const stringParser = (val: string): string => String(val).trim()
  const dateParser = (val: string): Date => {
    const v = parseDate(val)
    if (!v) {
      throw new Error(`Expect ${val} to be valid date`)
    }
    return v
  }
  const amountParser = (val: string): Decimal => new Decimal(val)

  return sheet.rows.map(row => ({
    [ColumnType.caseNumber]: parseVal(row, ColumnType.caseNumber, stringParser),
    [ColumnType.companyCode]: parseVal(row, ColumnType.companyCode, stringParser),
    [ColumnType.companyName]: parseVal(row, ColumnType.companyName, stringParser),
    [ColumnType.debtorAddress]: parseVal(row, ColumnType.debtorAddress, stringParser),
    [ColumnType.debtorCity]: parseVal(row, ColumnType.debtorCity, stringParser),
    [ColumnType.debtorPhoneNumber]: parseVal(row, ColumnType.debtorPhoneNumber, stringParser),
    [ColumnType.borrowerPhoneNumber]: parseVal(row, ColumnType.borrowerPhoneNumber, stringParser),
    [ColumnType.invoiceDate]: parseVal(row, ColumnType.invoiceDate, dateParser),
    [ColumnType.invoicePaymentDate]: parseVal(row, ColumnType.invoicePaymentDate, dateParser),
    [ColumnType.currency]: parseVal(row, ColumnType.currency, stringParser),
    [ColumnType.principalDebt]: parseVal(row, ColumnType.principalDebt, amountParser),
    [ColumnType.debtorEmail]: parseVal(row, ColumnType.debtorEmail, stringParser),
    [ColumnType.interestRate]: parseVal(row, ColumnType.interestRate, amountParser)
  }))
}

const DUPLICATE_CASE_NUMBER_MSG = 'Duplicate casecard number.'
const validateDocumentNumberUniqueness = (rows: string[][], errors: string[][][], columnTypes: ColumnType[]): string[][][] => {
  if (columnTypes.includes(ColumnType.caseNumber)) {
    const docNoColIdx = columnTypes.indexOf(ColumnType.caseNumber)
    const key = (row: string[]) => row[docNoColIdx]
    const casecardNumberCounts = R.countBy(key, rows)
    return errors.map((errorRow, rowIdx) => {
      const countKey = key(rows[rowIdx])
      const colErrors = errorRow[docNoColIdx]
      if (casecardNumberCounts[countKey] > 1) {
        if (!colErrors.includes(DUPLICATE_CASE_NUMBER_MSG)) {
          return arrayrepl(errorRow, docNoColIdx, [...colErrors, DUPLICATE_CASE_NUMBER_MSG])
        }
      } else if (colErrors.includes(DUPLICATE_CASE_NUMBER_MSG)) {
        return arrayrepl(errorRow, docNoColIdx, R.without([DUPLICATE_CASE_NUMBER_MSG], colErrors))
      }

      return errorRow
    })
  }
  return errors
}

const validateCompanyNameAndCodeUniqueness = (rows: string[][], errors: string[][][], columnTypes: ColumnType[]): string[][][] => {
  if (columnTypes.includes(ColumnType.companyCode) && columnTypes.includes(ColumnType.companyName)) {
    const companyCodeColIdx = columnTypes.indexOf(ColumnType.companyCode)
    const companyNameColIdx = columnTypes.indexOf(ColumnType.companyName)

    const companyName2Code: Record<string, string> = {}

    return errors.map((errorRow, rowIdx) => {
      const name = rows[rowIdx][companyNameColIdx]
      const code = rows[rowIdx][companyCodeColIdx]
      const colErrors = errorRow[companyCodeColIdx]
      if (!companyName2Code[name]) {
        companyName2Code[name] = code
      } else if (companyName2Code[name] !== code) {
        const msg = `Row ${rowIdx + 1} has the same company name, but different company code ${
          companyName2Code[name]
        }. Name and code must always match.`
        return arrayrepl(errorRow, companyCodeColIdx, [
          ...colErrors.filter(err => !err.includes('has the same company name, but different company code')),
          msg
        ])
      }
      if (colErrors.find(err => err.includes('has the same company name, but different company code'))) {
        return arrayrepl(
          errorRow,
          companyCodeColIdx,
          colErrors.filter(err => !err.includes('has the same company name, but different company code'))
        )
      }
      return errorRow
    })
  }
  return errors
}

// validation logic is overcomplicated and needs rethinking. but I'm tired, sorry
export const complexValidation = (rows: string[][], errors: string[][][], columnTypes: ColumnType[]): string[][][] =>
  [validateCompanyNameAndCodeUniqueness, validateDocumentNumberUniqueness].reduce(
    (errs, validator) => validator(rows, errs, columnTypes),
    errors
  )
