import React, { FC, useMemo, useCallback } from 'react'
import { Sheet, RawWorkbook, SheetConfig } from '@/models/casecard_import/xls'
import Container from '@/components/layouts/Container'
import { usePlan } from '@/hooks/casecard_import/usePlan'
import { usePromise } from '@/hooks/usePromise'
import * as R from 'ramda'
import Button from 'antd/lib/button'
import { useImport, ImportError } from '@/hooks/casecard_import/useImport'
import Spinner from '@/components/Spinner'
import styled from 'styled-components'
import Alert from 'antd/lib/alert'
import Result from 'antd/lib/result'
import { Link } from 'react-router-dom'
import { Plan, ImportOptions, ImportContext } from '@/models/casecard_import/plan'
import { DownloadInvoicesButton } from '@/components/DownloadInvoicesButton'
import { useCurrentClient } from '@/hooks/useCurrentClient'

// make tip text fit
const WiddeSpinner = styled(Spinner)`
  min-width: 200px;
`

interface ImportConfirmStageProps {
  sheet: Sheet
  importOptions: ImportOptions
  raw: RawWorkbook
  file: File
  importContext: ImportContext
  initialSheetConfig: SheetConfig
}

interface Stats {
  debtors_created: number
  existing_debtors: number
  casecards_updated: number
  casecards_created: number
  contacts_created: number
  reminders_created: number
  suspended_reminders: number
}

export const CasecardImportConfirmStage: FC<ImportConfirmStageProps> = props => {
  const { sheet, importOptions, raw, file, importContext, initialSheetConfig } = props

  const client = useCurrentClient()

  const [importfn, importPromise] = useImport()

  const { result: imported, loading: loadingImport, error: importError } = usePromise<{ importFileId: number }, ImportError>(importPromise)

  const planPromise = usePlan(sheet, importOptions, importContext, initialSheetConfig, [!!importError])

  const { result: plan, loading: loadingPlan, error: planError } = usePromise<Plan, Error>(planPromise)

  const importCallback = useCallback(() => {
    if (plan && importfn) {
      importfn(plan, file, raw)
    }
  }, [plan, importfn, file, raw])

  const stats: Stats | null = useMemo(() => {
    if (plan) {
      console.log(plan)
      const allReminders = [...plan.new_debtors.flatMap(d => d.reminders), ...plan.update_debtors.flatMap(d => d.new_reminders)]
      const retv: Stats = {
        debtors_created: plan.new_debtors.length,
        existing_debtors: plan.update_debtors.length,
        casecards_updated: R.sum(plan.update_debtors.map(d => d.update_casecards.length)),
        casecards_created:
          R.sum(plan.new_debtors.map(d => d.casecards.length)) + R.sum(plan.update_debtors.map(d => d.new_casecards.length)),
        contacts_created: R.sum(plan.new_debtors.map(d => d.contacts.length)) + R.sum(plan.update_debtors.map(d => d.new_contacts.length)),
        reminders_created: allReminders.length,
        suspended_reminders: allReminders.filter(reminder => reminder.is_suspended).length
      }
      return retv
    }
    return null
  }, [plan])

  const total = useMemo(() => (stats ? R.sum(Object.values(stats)) : 0), [stats])

  const loading = loadingPlan || loadingImport

  // @TODO we could expand to show specifics, if wanted
  return (
    <Container>
      {imported && (
        <Result
          status='success'
          title={`Successfully imported data from ${file.name}!`}
          extra={
            <>
              {importOptions.generateReminders && (
                <Link to={`/reminders?f_import_file_id=${imported.importFileId}`} key='reminders'>
                  <Button type='primary'>View imported reminders</Button>
                </Link>
              )}
              {importOptions.importCasecards && (
                <>
                  <Link to={`/casecards?f_import_file=${imported.importFileId}`} key='casecards'>
                    <Button type='primary'>View imported casecards</Button>
                  </Link>
                  {!!client.category?.invoice_template_id && (
                    <DownloadInvoicesButton importFileId={imported.importFileId}>Download casecards</DownloadInvoicesButton>
                  )}
                </>
              )}
            </>
          }
        />
      )}
      {importError && <Alert type='error' message={importError.message} />}
      {planError && <Alert type='error' message={planError.message} />}
      {loadingPlan && <WiddeSpinner tip='Calculating import plan...' noDelay={true} />}
      {loadingImport && <WiddeSpinner tip='Importing data...' noDelay={true} />}
      {!loading && !imported && !!stats && !!total && (
        <>
          <h3>Import statistics:</h3>
          {stats.debtors_created > 0 && <p>New debtors: {stats.debtors_created}</p>}
          {stats.existing_debtors > 0 && <p>Existing debtors: {stats.existing_debtors}</p>}
          {stats.casecards_created > 0 && <p>New casecards: {stats.casecards_created}</p>}
          {stats.casecards_updated > 0 && <p>Existing casecards: {stats.casecards_updated}</p>}
          {stats.contacts_created > 0 && <p>New contacts: {stats.contacts_created}</p>}
          {stats.reminders_created > 0 && (
            <p>
              New reminders: {stats.reminders_created} {stats.suspended_reminders ? `(${stats.suspended_reminders} suspended)` : ''}
            </p>
          )}
          <Button onClick={importCallback} type='primary'>
            Import!
          </Button>
        </>
      )}
      {!loading && !imported && stats && !total && <p>Nothing to import.</p>}
    </Container>
  )
}
