import React, { FC, useMemo, useCallback, useRef, useState, useEffect } from 'react'
import {
  SheetFormatNames,
  SheetFormat,
  Sheet,
  CurrentWorkbook,
  columnTypeNames,
  ColumnType,
  sheetFormats
} from '@/models/casecard_import/xls'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import styled from 'styled-components'
import ReactDataGrid, { Column, DataGridHandle, RowsUpdateEvent } from 'react-data-grid'
import 'react-data-grid/dist/react-data-grid.css'
import { DivWithDimensions, DivWithDimensionsProps } from '@/components/DivWithDimensions'
import { CellFormatter } from '../components/CellFormatter'
import { arrayrepl, matrixrepl } from '@/utils/array'
import { HeaderRenderer } from '../components/HeaderRenderer'
import { columnTypes } from '../columns'
import { validateValue, complexValidation } from '@/utils/casecard_xls'
import Button from 'antd/lib/button'
import { DeleteOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import Alert from 'antd/lib/alert'
import { Tabs, Checkbox } from 'antd'
import { ImportContext, ImportOptions } from '@/models/casecard_import/plan'
import { useNavigate } from 'react-router'

const { TabPane } = Tabs

const GeneralAlert = styled(Alert)`
  margin-top: 1em;
`

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GridContainer = styled(({ hasTabs, ...props }: DivWithDimensionsProps & { hasTabs: boolean }) => <DivWithDimensions {...props} />)`
  flex: 1;
  overflow: hidden;
  margin-top: ${(props: { hasTabs: boolean }) => (props.hasTabs ? '0' : '1em')};
`

const StyledTabs = styled(Tabs)`
  margin-top: 1em;

  .ant-tabs-bar {
    margin-bottom: 0;
    border-bottom: none;
  }

  .ant-tabs-card {
    border-bottom: none !important;
  }

  .ant-tabs-content {
    display: none;
  }
`

const ImportButtonItem = styled(Form.Item)`
  float: right;
`

interface ErrorBrowseIconProps extends AntdIconProps {
  disabled: boolean
}

const ErrorBrowseIconLeft = styled(LeftOutlined as React.ExoticComponent<ErrorBrowseIconProps>)`
  ${(props: ErrorBrowseIconProps) => (props.disabled ? 'opacity: 0.1;' : '')}
`

const ErrorBrowseIconRight = styled(RightOutlined as React.ExoticComponent<ErrorBrowseIconProps>)`
  ${(props: ErrorBrowseIconProps) => (props.disabled ? 'opacity: 0.1;' : '')}
`

interface ImportPreviewStageProps {
  setFormat: (format: SheetFormat) => void
  workbook: CurrentWorkbook
  setWorkbook: (workbook: CurrentWorkbook) => void
  importOptions: ImportOptions
  updateImportOptions: (opts: Partial<ImportOptions>) => void
  errorCount: number
  isValid: boolean
  generalErrors: string[]
  generalWarnings: string[]
  importContext: ImportContext
}

interface Row {
  [key: number]: string
}

const EMPTY_ARR: any[] = []

export const CasecardImportPreviewStage: FC<ImportPreviewStageProps> = props => {
  const {
    workbook,
    setFormat,
    setWorkbook,
    importOptions,
    updateImportOptions,
    errorCount,
    isValid,
    generalErrors,
    generalWarnings,
    importContext
  } = props

  const sheet: Sheet = workbook.sheets[workbook.selectedSheetName]

  const gridRef = useRef<DataGridHandle>(null)

  const updateCurrentSheet = useCallback(
    (changes: Partial<Sheet>): void => {
      setWorkbook({
        ...workbook,
        sheets: {
          ...workbook.sheets,
          [sheet.name]: {
            ...sheet,
            ...changes
          }
        }
      })
    },
    [setWorkbook, workbook, sheet]
  )

  const setColumnType = useCallback(
    (colIdx: number, columnType: ColumnType) => {
      const columns = sheet.columns.map((column, idx) => ({
        ...column,
        type: idx === colIdx ? columnType : idx !== colIdx && column.type === columnType ? ColumnType.skip : column.type
      }))
      updateCurrentSheet({
        // set selected column type. if any other columns have the same type, set them to "skip"
        columns,
        // validate only this cell
        errors: complexValidation(
          sheet.rows,
          sheet.errors.map((row, rowIdx) => arrayrepl(row, colIdx, validateValue(sheet.rows[rowIdx][colIdx], columnType, importContext))),
          columns.map(c => c.type)
        )
      })
    },
    [sheet, updateCurrentSheet, importContext]
  )

  const deleteRow = useCallback(
    (rowIdx: number) => {
      updateCurrentSheet({
        rows: sheet.rows?.filter((_, idx) => idx !== rowIdx),
        errors: sheet.errors?.filter((_, idx) => idx !== rowIdx)
      })
    },
    [sheet, updateCurrentSheet]
  )

  // `HeaderRenderer` does not necessarily get rererndered if setColumnType changes,
  // so we use ref so change always has current callback
  const setColumnTypeRef = useRef(setColumnType)
  setColumnTypeRef.current = setColumnType

  const columns: Array<Column<Row>> = useMemo(
    () => [
      {
        key: 'delete',
        name: '',
        resizable: false,
        editable: false,
        resizble: false,
        sortable: false,
        width: 40,
        maxWidth: 40,
        cellClass: 'delete-col',
        formatter: fprops => (
          <Button title='delete' size='small' type='link' icon={<DeleteOutlined />} onClick={() => deleteRow(fprops.rowIdx)} />
        )
      },
      ...sheet.columns.map((col, idx) => ({
        key: String(idx),
        name: col.label || '???',
        resizable: true,
        editable: true,
        formatter: (fprops: { row: Row }) => (
          <CellFormatter
            value={fprops.row[idx]}
            columnConfig={columnTypes[col.type]}
            errors={(fprops.row as any).__errors[idx] || EMPTY_ARR}
          />
        ),
        // heuristics for a reasonable column width
        width: Math.max(Math.max((col.label || '???').length, columnTypeNames[col.type].length) * 9 + 15, 120),
        columnType: col.type,
        headerRenderer: () => <HeaderRenderer column={col} onColumnTypeChange={columnType => setColumnTypeRef.current(idx, columnType)} />
      }))
    ],
    [sheet, deleteRow]
  )

  const [selectedErrorIndex, setSelectedErrorIndex] = useState(-1)

  // if number of errors changes, reset selected error
  useEffect(() => setSelectedErrorIndex(-1), [errorCount])

  const selectNextError = useCallback(() => {
    if (selectedErrorIndex < errorCount - 1) {
      setSelectedErrorIndex(idx => idx + 1)
    }
  }, [setSelectedErrorIndex, selectedErrorIndex, errorCount])

  const selectPrevError = useCallback(() => {
    if (selectedErrorIndex > 0) {
      setSelectedErrorIndex(idx => idx - 1)
    }
  }, [setSelectedErrorIndex, selectedErrorIndex])

  // select cell of currently selected error. currently involves scan of entire error matrix, not very efficient
  useEffect(() => {
    const grid = gridRef.current
    if (grid && selectedErrorIndex > -1 && selectedErrorIndex < errorCount) {
      let errNo = -1
      for (let rowIdx = 0; rowIdx < sheet.errors.length; rowIdx++) {
        for (let colIdx = 0; colIdx < sheet.errors[rowIdx].length; colIdx++) {
          if (sheet.errors[rowIdx][colIdx].length) {
            errNo += 1
            if (errNo === selectedErrorIndex) {
              grid.selectCell({ rowIdx, idx: colIdx })
              return
            }
          }
        }
      }
    }
  }, [selectedErrorIndex, errorCount, sheet.errors])

  const rows = useMemo(
    () =>
      sheet.rows.map((r, rowIdx) => {
        const row: Row = {
          __errors: sheet.errors[rowIdx] || ([] as string[][])
        } as any
        columns.forEach((_, colIdx) => {
          const value = (r || [])[colIdx] || ''
          row[colIdx] = value
        })
        return row
      }),
    [sheet.rows, columns, sheet.errors]
  )

  // validate & set new value on user edit
  const onRowsUpdate = useCallback(
    ({ fromRow, updated }: RowsUpdateEvent<Row>) => {
      const keys = Object.keys(updated)
      if (keys.length) {
        const cellidx = Number(keys[0])
        const newValue = updated[cellidx] as string
        if (updated[cellidx] !== sheet.rows[fromRow][cellidx]) {
          const rows = arrayrepl(sheet.rows, fromRow, arrayrepl<string>(sheet.rows[fromRow], cellidx, newValue))
          let errors = matrixrepl(sheet.errors, fromRow, cellidx, validateValue(newValue, sheet.columns[cellidx].type, importContext))
          if ([ColumnType.caseNumber, ColumnType.companyCode].includes(sheet.columns[cellidx].type)) {
            errors = complexValidation(
              rows,
              errors,
              sheet.columns.map(c => c.type)
            )
          }
          updateCurrentSheet({
            rows,
            errors
          })
        }
      }
    },
    [sheet, updateCurrentSheet, importContext]
  )

  const nav = useNavigate()

  const goNext = useCallback(() => nav('/import_casecards/confirm'), [nav])

  const sheetNames = useMemo(() => Object.keys(workbook.sheets), [workbook.sheets])

  const onTabChange = useCallback(
    (selectedSheetName: string) =>
      setWorkbook({
        ...workbook,
        selectedSheetName
      }),
    [workbook, setWorkbook]
  )

  return (
    <Container>
      <Form layout='inline'>
        <Form.Item label='Format'>
          <Select value={workbook.format} onChange={setFormat}>
            {sheetFormats
              .filter(fmt => !(fmt === SheetFormat.custom && workbook.format !== SheetFormat.custom))
              .map(fmt => (
                <Select.Option key={fmt} value={fmt}>
                  {SheetFormatNames[fmt]}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Checkbox checked={importOptions.importCasecards} onChange={chg => updateImportOptions({ importCasecards: chg.target.checked })}>
            Import casecards
          </Checkbox>
        </Form.Item>
        {/* <Form.Item>
          <Checkbox
            disabled={!importOptions.importInvoices}
            checked={importOptions.generateReminders}
            onChange={chg => updateImportOptions({ generateReminders: chg.target.checked })}
          >
            Generate reminders
          </Checkbox>
        </Form.Item> */}
        {/* <Form.Item label=''>
          <Checkbox
            disabled={!importOptions.importInvoices}
            checked={importOptions.markOldInvoicesAsPaid}
            onChange={chg => updateImportOptions({ markOldInvoicesAsPaid: chg.target.checked })}
          >
            Mark old invoices as paid
          </Checkbox>
        </Form.Item> */}
        <Form.Item>
          <>
            Errors: {errorCount}
            &nbsp;
            <ErrorBrowseIconLeft disabled={selectedErrorIndex <= 0} title='Previous error' onClick={selectPrevError} />
            <ErrorBrowseIconRight disabled={selectedErrorIndex >= errorCount - 1} title='Next error' onClick={selectNextError} />
          </>
        </Form.Item>
        <ImportButtonItem>
          <Button onClick={goNext} disabled={!isValid} type='primary'>
            Next
          </Button>
        </ImportButtonItem>
      </Form>
      {!!generalErrors.length && <GeneralAlert message={generalErrors.join(' ')} type='error' />}
      {!!generalWarnings.length && <GeneralAlert message={generalWarnings.join(' ')} type='warning' />}
      {sheetNames.length > 1 && (
        <StyledTabs type='card' activeKey={sheet.name} onChange={onTabChange}>
          {sheetNames.map(sheetName => (
            <TabPane tab={sheetName} key={sheetName} />
          ))}
        </StyledTabs>
      )}
      <GridContainer hasTabs={sheetNames.length > 1}>
        {({ height }) => (
          <ReactDataGrid<Row, keyof Row>
            ref={gridRef}
            columns={columns}
            rows={rows}
            height={height}
            headerRowHeight={60}
            onCheckCellIsEditable={() => true}
            onRowsUpdate={onRowsUpdate}
          />
        )}
      </GridContainer>
    </Container>
  )
}
