export interface SheetColumn {
  label: string
  type: ColumnType
}

export type Cell = [string, string[]] // value, errors

export interface Sheet {
  name: string
  columns: SheetColumn[]
  rows: string[][] // row:column:value
  errors: string[][][]
}

export interface CurrentWorkbook {
  format: SheetFormat
  selectedSheetName: string
  sheets: { [key: string]: Sheet }
}

export interface RawSheet {
  name: string
  rows: unknown[][]
}

export interface RawWorkbook {
  sheets: RawSheet[]
}

export enum SheetFormat {
  // eslint-disable-next-line prettier/prettier
  simple = 'simple',
  simpleNoHeader = 'simple_no_header',
  silva = 'silva',
  bd = 'bd',
  sgb = 'sgb',
  uprent = 'uprent',
  custom = 'custom' // previous use config loaded from db
}
export interface SheetConfig {
  format: SheetFormat
  columnTypes?: ColumnType[]
}

export const sheetFormatsWithoutContactsImport = [SheetFormat.sgb]

export const SheetFormatNames: { [key in SheetFormat]: string } = {
  [SheetFormat.simple]: 'Basic',
  [SheetFormat.simpleNoHeader]: 'Basic (no header)',
  [SheetFormat.silva]: 'SILVA',
  [SheetFormat.bd]: 'BD',
  [SheetFormat.sgb]: 'Scandinavian Good Business',
  [SheetFormat.uprent]: 'Uprent',
  [SheetFormat.custom]: 'Custom'
}

export const sheetFormats = Object.values(SheetFormat)

export enum ColumnType {
  skip = 'skip',
  caseNumber = 'case_number',
  companyCode = 'debtor_code',
  companyName = 'debtor_name',
  debtorAddress = 'debtor_address',
  debtorCity = 'debtor_city',
  debtorPhoneNumber = 'debtor_phone_number',
  borrowerPhoneNumber = 'borrower_phone_number',
  invoiceDate = 'invoice_date',
  invoicePaymentDate = 'invoice_payment_date',
  currency = 'currency',
  principalDebt = 'principal_debt',
  debtorEmail = 'debtor_email',
  interestRate = 'interest_rate'
}

export const columnTypes = Object.values(ColumnType)

const defaultInvoiceImportRequiredColumns = [
  ColumnType.caseNumber,
  ColumnType.companyCode,
  ColumnType.companyName,
  ColumnType.debtorAddress,
  ColumnType.debtorCity,
  ColumnType.debtorPhoneNumber,
  ColumnType.borrowerPhoneNumber,
  ColumnType.invoiceDate,
  ColumnType.invoicePaymentDate,
  ColumnType.principalDebt,
  ColumnType.debtorEmail,
  ColumnType.interestRate
]

export const columnsRequiredForInvoiceImport: { [key in SheetFormat]: ColumnType[] } = {
  [SheetFormat.simpleNoHeader]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.simple]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.silva]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.bd]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.uprent]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.custom]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.sgb]: defaultInvoiceImportRequiredColumns
}

export const columnTypeNames: { [key in ColumnType]: string } = {
  [ColumnType.skip]: 'Skip',
  [ColumnType.caseNumber]: 'Case Number',
  [ColumnType.companyCode]: 'Company Code',
  [ColumnType.companyName]: 'Company Name',
  [ColumnType.debtorAddress]: 'Address',
  [ColumnType.debtorCity]: 'City',
  [ColumnType.debtorPhoneNumber]: 'Debtor Phone Number',
  [ColumnType.borrowerPhoneNumber]: 'Borrower Phone Number',
  [ColumnType.invoiceDate]: 'Invoice Date',
  [ColumnType.invoicePaymentDate]: 'Invoice Payment Date',
  [ColumnType.currency]: 'Currency',
  [ColumnType.principalDebt]: 'Principal Debt',
  [ColumnType.debtorEmail]: 'Debtor Email',
  [ColumnType.interestRate]: 'Interest Rate'
}

export const contactColumnTypes = [ColumnType.debtorAddress, ColumnType.debtorPhoneNumber, ColumnType.debtorEmail]
